// src/pages/About.js
import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h2>About Us</h2>
      <p>WritingFreelancer as the name suggests is a sheer service providing the students with the best of assignment writing help. This service is rendered based out of Kurunegala, Sri Lanka and the primary objective is to assist students in attaining their academic aspirations attainable through the provision of quality custom written work.</p>
    </div>
  );
};

export default About;
