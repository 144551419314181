// src/pages/Services.js
import React from 'react';

const Services = () => {
  return (
    <div className="services">
      <h2>Our Services</h2>
      <ul>
        <li>Assignment Writing</li>
        <li>Essay Writing</li>
        <li>Research Paper Writing</li>
        <li>Proofreading and Editing</li>
      </ul>
    </div>
  );
};

export default Services;
