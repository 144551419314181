// src/pages/Contact.js
import React from 'react';

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <p><strong>Address:</strong> Kurunegala, Sri Lanka, 60000</p>
      <p><strong>Contact Number:</strong> +94 72 464 0438</p>
      <p><strong>Email:</strong> writingfreelancer.online@gmail.com</p>
      <p>
        <strong>Facebook Page:</strong> 
        <a href="https://web.facebook.com/writingfreelancer" target="_blank" rel="noopener noreferrer">
          facebook.com/writingfreelancer
        </a>
      </p>
    </div>
  );
};

export default Contact;
