// src/pages/Home.js
import React from 'react';
import BusinessInfo from '../components/BusinessInfo';

const Home = () => {
  return (
    <div className="home">
      <h2>Welcome !</h2>
      <p>Essays, term papers, research papers, and other complex works that are challenging to handle independently are what we offer efficient solutions for.</p>
      <BusinessInfo />
      <div className="facebook-page">
        <div className="fb-page"
             data-href="https://web.facebook.com/writingfreelancer"
             data-tabs="timeline"
             data-width=""
             data-height=""
             data-small-header="false"
             data-adapt-container-width="true"
             data-hide-cover="false"
             data-show-facepile="true">
          <blockquote cite="https://web.facebook.com/writingfreelancer" className="fb-xfbml-parse-ignore">
            <a href="https://web.facebook.com/writingfreelancer">WritingFreelancer</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default Home;
