// src/components/Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.webp'; // Adjust the path if necessary

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul>
        <li>
          <img src={logo} alt="WritingFreelancer Logo" className="logo" />
        </li>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;
